import * as React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import {
  DocumentationArticleSlice,
  DocumentationNavigationSlice,
} from '../components/Slices'
import Layout from '../components/Layout/Layout'
import Seo from '../components/Layout/Seo'
import { Section } from '../components/Layout'

const Container = styled.div`
  display: flex;
`

const DocumentationPage = () => {
  const pageName = 'Documentation'
  const breadcrumbList = [
    { position: 1, name: pageName, path: 'documentation' },
  ]
  const data = useStaticQuery(graphql`
    query DocumentationTitlesQuery {
      allContentfulDocumentationPage(
        sort: {fields: [documentationCategory___title, sortOrder], order: [ASC, ASC]}
      ) {
        nodes {
          title
          slug
          documentationCategory {
            slug
            title
          }
        }
      }
    }
  `)

  
  const getStartedNodes = data.allContentfulDocumentationPage.nodes
    .filter(article => article.documentationCategory.title === "Setup Guide")

  const templateDesignNodes = data.allContentfulDocumentationPage.nodes
    .filter(article => article.documentationCategory.title === "Template Design")

  const faqNodes = data.allContentfulDocumentationPage.nodes
    .filter(article => article.documentationCategory.title === "FAQ")

  const next = getStartedNodes[0]
  return (
    <Layout>
      <Seo
        title={pageName}
        pathname="documentation"
        breadcrumbList={breadcrumbList}
      />
      <Section>
        <Container>
          <DocumentationNavigationSlice getStartedArticles={getStartedNodes} templateDesignArticles={templateDesignNodes} faqArticles={faqNodes} />
          <DocumentationArticleSlice
            templateDesignSlug={`/documentation/${templateDesignNodes[0]?.documentationCategory?.slug}/${templateDesignNodes[0]?.slug}`}
            setupSlug={`/documentation/${getStartedNodes[0]?.documentationCategory?.slug}/${getStartedNodes[0]?.slug}`}
            faqSlug={`/documentation/${faqNodes[0]?.documentationCategory?.slug}/${faqNodes[0]?.slug}`}
            next={next}
          />
        </Container>
      </Section>
    </Layout>
  )
}
export default DocumentationPage
